import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },


})
// const store = new Vuex.Store({
//   modules:{},
//   getters,
//   state,
//   mutations,
//   actions
// })
// export default store
